import React, { useState } from "react";
import EquityModal from "../EquityModal";
import styles from "./index.module.css";
import ProgressBar from "../ProgressBar";
import Button from "../ButtonV2";
import { EquityContribution } from "../EquityContribution";
import { RootState, useAppSelector } from "../../store/store";
import Loader from "../Loader";
import { formatNumber } from ".";
import { useGetEquityContributionStatusQuery } from "../../services/api";

interface Props {
  equityResultLoading: boolean;
  error: string | null;
  isLoading: boolean;
  showEquityChart: boolean;
  equityContributionSubmit: string;
  totalPaymentAmount: string;
  isSoftCommitComplete: boolean | undefined;
  setIsSoftCommitComplete: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  equityContribution: string;
  // formatNumber: (value: string) => string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  submitEquityData: () => void;
  setShowEquityChart: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  formData: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmitEquity: (data: any) => void;
  toggleModal: () => void;
  equityContributionLoader: boolean;
  equityFormLoader: boolean;
  setEquityFormLoader: React.Dispatch<React.SetStateAction<boolean>>;
  apaData?: IApaDoc;
}

const Tile5 = ({
  equityResultLoading,
  error,
  isLoading,
  showEquityChart,
  equityContributionSubmit,
  totalPaymentAmount,
  isSoftCommitComplete,
  setIsSoftCommitComplete,
  equityContribution,
  // formatNumber,
  handleInputChange,
  submitEquityData,
  setShowEquityChart,
  setIsOpen,
  isOpen,
  formData,
  handleChange,
  handleSubmitEquity,
  toggleModal,
  equityContributionLoader,
  equityFormLoader,
  setEquityFormLoader,
  apaData,
}: Props) => {
  const [openNoForm, setOpenNoForm] = useState(false);
  const entityInformationCollected = useAppSelector(
    (state: RootState) => state.stage.entityInformationCollected
  );

  const { data: ecData, refetch: refetchEC } =
    useGetEquityContributionStatusQuery();

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  return (
    <div className={`${styles.tile} ${styles.customTile}`}>
      <h2 className={styles.heading} style={{ height: "20%" }}>
        Equity Contribution
      </h2>
      <div className={styles.tile4}>
        {showEquityChart ? (
          <>
            {isSoftCommitComplete ? (
              <button
                onClick={() => setIsSoftCommitComplete(false)}
                className={`${styles.tilesButton} mt-3 ${styles.entityBtn}`}
              >
                Re-enter equity equityContribution
              </button>
            ) : // entityInformationCollected

            (!apaData?.CAF_Signed &&
                !apaData?.MNDA_Signed &&
                !apaData?.OMA_Signed &&
                !apaData?.request_id) ||
              (apaData?.CAF_Signed &&
                apaData?.MNDA_Signed &&
                apaData?.OMA_Signed) ? (
              <form onSubmit={handleSubmit}>
                <div className={`${styles.inputRow} flex mt-4 justify-center`}>
                  <input
                    className={`w-full p-[2px] pl-[5px] border border-gray-400`}
                    placeholder="Annual Equity Contribution"
                    type="text"
                    name="text"
                    autoComplete="off"
                    value={formatNumber(equityContribution)}
                    onChange={handleInputChange}
                  />
                  <button
                    className={`bg-[#3B755E] rounded-sm text-white ml-2 w-[7rem]`}
                    onClick={submitEquityData}
                    disabled={equityResultLoading}
                  >
                    {equityContributionLoader ? (
                      <Loader isLoading={true} size={12} />
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                </div>
                {error && (
                  <div className="text-red-500 text-sm text-center">
                    {error}
                  </div>
                )}
              </form>
            ) : (
              <p className="text-xs mx-3">
                Your entity is currently being formed. This section will update
                once you receive a confirmation email that your entity has been
                created successfully.
              </p>
            )}

            {/* <div className={styles.tile2desp}>
        In order to meet this goal, your payment of 1/8 is due June 1.
      </div> */}
            {equityResultLoading ? (
              <h1 className="text-white flex justify-center mt-12">
                <Button isLoading={isLoading} title="" variant="light4" />
              </h1>
            ) : (
              <div className={styles.tile4Result}>
                <div className="">
                  <ProgressBar value={25} />
                </div>
                <div className="mt-2">
                  <span className="text-3xl">
                    {totalPaymentAmount
                      ? formatNumber(totalPaymentAmount)
                      : "$Na"}
                  </span>
                  /{" "}
                  <span>
                    {equityContributionSubmit
                      ? formatNumber(equityContributionSubmit)
                      : "$Na"}
                  </span>
                </div>
              </div>
            )}
          </>
        ) : ecData?.data.exist ? (
          <p className="text-xs mx-3">
            Your entity is currently being formed. This section will update once
            you receive a confirmation email that your entity has been created
            successfully.
          </p>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className={styles.tile2desp}>
              Do you have an entity? If your answer is NO, an Entity will be set
              up for you.
            </div>
            <div className="flex mt-3">
              <button
                className={styles.tileButtons}
                onClick={() => setIsOpen(true)}
              >
                YES
              </button>
              <EquityModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmitEquity}
                toggleModal={toggleModal}
                equityFormLoader={equityFormLoader}
                setEquityFormLoader={setEquityFormLoader}
              />
              <button
                className={styles.equNoBtn}
                onClick={() => {
                  setOpenNoForm(true);
                }}
              >
                NO
              </button>
              {openNoForm && (
                <EquityContribution
                  setOpenNoForm={setOpenNoForm}
                  setShowEquityChart={setShowEquityChart}
                  refetchEC={refetchEC}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tile5;

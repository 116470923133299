import { RootState, useAppDispatch } from "../../store/store";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import Logo from "../../assets/images/InceptionFinicallyLogo.png";
import profileImg from "../../assets/images/inceptionAdmin.jpeg";
import SliderOption from "./sliderOption";
import DashboardTiles from "./DashboardTiles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { yupResolver } from "@hookform/resolvers/yup";
import { cpaInfoSchema } from "../../yup";
import {
  useGetApaDataQuery,
  useGetContactOwnerQuery,
  useGetCpaQuery,
  useUpdateCpaMutation,
  useUploadLicenceMutation,
} from "../../services/api";
import Loader from "../Loader";
import config from "../../configs/env.development";
import useWindowResize from "../../hooks/useWindowResize";
import Navbar from "./Navbar";

interface FieldValues {
  cpaName: string;
  cpaEmail: string;
  cpaPhone: string;
  profilePic?: string;
}

const PurlDashboard = () => {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem("token") || "";
  const loginData = useSelector((state: RootState) => state.login);
  const stageData = useSelector((state: RootState) => state.stage);
  const { reducers: tiles } = useSelector((state: RootState) => state.tile);
  const [uploadLicence, { isLoading: isUploading }] =
    useUploadLicenceMutation();
  const { data: cpaData, refetch: refetchCpa } = useGetCpaQuery();
  const { data: contactOwner, refetch: refetchCO } = useGetContactOwnerQuery(
    loginData.zohoCustomerId
  );
  const [updateCpa, { isLoading: cpaLoading }] = useUpdateCpaMutation();
  const { data: apaData, refetch: refetchApa } = useGetApaDataQuery({
    id: loginData.zohoCustomerId,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    control,
  } = useForm<FieldValues>({
    resolver: yupResolver(cpaInfoSchema),
  });
  const { width: screenWidth } = useWindowResize();

  const [previewUrl, setPreviewUrl] = useState("");
  const [isCpa, setIsCpa] = useState(false);
  const [addCpa, setAddCpa] = useState(false);
  const [fileId, setFileId] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);

  const handleFileChange = async (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      console.log("File selected:", file);
      const preview = URL.createObjectURL(file);

      // setPreviewUrl(preview);
      console.log(preview);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("contactId", loginData.zohoCustomerId);
      formData.append("type", "user");

      try {
        const response: any = await uploadLicence(formData);
        console.log(response);
        if (response.data.data.code == "SUCCESS") {
          const id = response.data.data.details.id;
          setPreviewUrl(preview);
          setFileId(id);
          getImgUrl();
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
      }
    }
  };

  const submitCpa: SubmitHandler<FieldValues> = async (values) => {
    console.log("cpa fields", values);
    console.log(fileId, "fileId");
    try {
      const sendData = {
        crm_Contact_ID: loginData.zohoCustomerId,
        cpaName: values.cpaName,
        cpaEmail: values.cpaEmail,
        cpaPhone: values.cpaPhone,
      };

      const result = await updateCpa(sendData).unwrap();
      console.log("result", result);
      if (result.data.code != "success") throw new Error(result?.message);
      setAddCpa(false);
      refetchCpa();
      reset();
    } catch (err) {
      console.log("err in updating cpa", err);
    }
  };

  const getImgUrl = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}/api/user/user-img/${loginData.zohoCustomerId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            observe: "response",
            responseType: "blob",
          },
        }
      );

      if (response.status != 200) throw new Error("image not found");
      const arrayBuffer = await response.arrayBuffer();
      const blob = new Blob([arrayBuffer], {
        type: "application/octet-stream",
      });

      const url = window.URL.createObjectURL(blob);
      console.log(url, "URL");
      if (!url) return;
      setPreviewUrl(url);
    } catch (err: any) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    getImgUrl();
  }, []);

  return (
    <div className={`bg-white w-full flex ${styles.getClass}`}>
      {(screenWidth > 900 || showSidebar) && (
        <div className={`${showSidebar ? "w-full" : "w-[404px]"} bg-[#090F21]`}>
          {showSidebar ? (
            <Navbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
          ) : (
            <div className="bg-[#303545] flex justify-center py-2 h-[10vh]">
              <img src={Logo} width={150} alt="logo" />
            </div>
          )}
          <div
            className={`${
              showSidebar ? "w-full" : "w-[345px]"
            } px-[2rem] mt-[6rem] mx-auto`}
          >
            <div className="bg-[#EFEFF42B] rounded-t-xl flex flex-col justify-center items-center p-3 pt-16 relative">
              <div className="absolute top-[-45%] w-[120px] h-[120px] overflow-hidden">
                <img
                  className="rounded-full h-full w-full object-cover"
                  width={120}
                  height={120}
                  src={previewUrl ? previewUrl : profileImg}
                  alt="img"
                />
                <label
                  htmlFor="profileImage"
                  className="bg-green-500 text-2xl flex items-center justify-center absolute w-[25px] h-[25px] bottom-2 right-2 text-white rounded-full"
                >
                  +
                </label>
                <Controller
                  name="profilePic"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="file"
                      id="profileImage"
                      className="rounded-full"
                      hidden
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => {
                        console.log("is it here");
                        field.onChange(e.target.files);
                        handleFileChange(e.target.files);
                      }}
                    />
                  )}
                />
                {/* {errors.file && <p>{errors.file.message}</p>} */}
              </div>
              <p className="font-semibold text-white text-[25px]">
                {loginData.firstName + " " + loginData.lastName}
              </p>
              <span className="text-white text-[21px]">-</span>
            </div>
            <div className="h-[400px] bg-[#1D2234]"></div>
            <div className="bg-[#EFEFF42B] py-8 px-8 rounded-b-xl">
              <div className="text-white">
                <p className="my-2 text-[17px] font-semibold">
                  Your Client relations Team
                </p>
                <div className="flex flex-col mb-3">
                  <span className="text-[17px] font-semibold">
                    {contactOwner?.data?.ownerName || (
                      <p className="text-center w-[200px] font-thin">-</p>
                    )}
                  </span>
                  <span className="text-[17px]">
                    {contactOwner?.data?.ownerRole || (
                      <p className="text-center w-[200px] font-thin">-</p>
                    )}
                  </span>
                  <span className="text-[17px]">
                    {contactOwner?.data?.ownerPhone}
                    {!contactOwner?.data?.ownerPhone && (
                      <p className="text-center w-[200px] font-thin">-</p>
                    )}
                  </span>
                  <span className="text-[17px]">
                    {contactOwner?.data?.ownerEmail || (
                      <p className="text-center w-[200px] font-thin">-</p>
                    )}
                  </span>
                </div>
                <div className="flex flex-col mb-3">
                  <span className="text-[17px] font-semibold w-full">
                    {contactOwner?.data?.subOwnerName}
                    {!contactOwner?.data?.subOwnerName && (
                      <p className="text-center w-[200px] font-thin">-</p>
                    )}
                  </span>
                  <span className="text-[17px]">
                    {contactOwner?.data?.subOwnerRole}
                    {!contactOwner?.data?.subOwnerRole && (
                      <p className="text-center w-[200px] font-thin">-</p>
                    )}
                  </span>
                  <span className="text-[17px]">
                    {contactOwner?.data?.subOwnerEmail}
                    {!contactOwner?.data?.subOwnerEmail && (
                      <p className="text-center w-[200px] font-thin">-</p>
                    )}
                  </span>
                  <span className="text-[17px]">
                    {contactOwner?.data?.subOwnerPhone}
                    {!contactOwner?.data?.subOwnerPhone && (
                      <p className="text-center w-[200px] font-thin">-</p>
                    )}
                  </span>
                </div>
                <div className="flex flex-col mb-3">
                  <span className="text-[17px] font-semibold">Your CPA</span>
                  {cpaData?.data.cpaEmail && (
                    <>
                      <span className="text-[17px]">
                        {cpaData?.data.cpaName}
                      </span>
                      <span className="text-[17px]">
                        {cpaData?.data.cpaPhone}
                      </span>
                      <span className="text-[17px]">
                        {cpaData?.data.cpaEmail}
                      </span>
                    </>
                  )}
                  {!cpaData?.data.cpaEmail && (
                    <button
                      onClick={() => setAddCpa(true)}
                      className="py-2 text-[17px] mt-2 bg-[#CFD8E4] rounded-sm text-black "
                    >
                      Add CPA
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="my-8 text-center">
            <button
              onClick={() =>
                window.open("https://zfrmz.com/fHcCGLMIBcMb6nFQr4yc")
              }
              className="bg-[#CFD8E4] w-[281px] h-[43px] text-[17px]"
            >
              SHARE PORTAL FEEDBACK
            </button>
          </div>
        </div>
      )}
      {!showSidebar && (
        <div className="w-full bg-[#EFEFF4]">
          {screenWidth < 900 && (
            <Navbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
          )}
          <SliderOption apaData={apaData} />
          <DashboardTiles apaData={apaData} refetchApa={refetchApa} />
        </div>
      )}
      {addCpa && (
        <div className="w-full h-full fixed flex justify-center items-center bg-opacity-40 bg-black z-10">
          <form
            onSubmit={handleSubmit(submitCpa)}
            className="w-1/3 bg-[#CFD8E4] p-8 rounded-xl text-center relative"
          >
            <IoIosCloseCircle
              onClick={() => setAddCpa(false)}
              className="absolute right-2 top-2 text-2xl"
            />
            <div className="flex flex-col w-full mb-4">
              <label className="text-black font-semibold text-start">
                CPA Name
              </label>
              <input
                {...register("cpaName")}
                className="p-2 text-[17px] rounded-md w-full"
              />
              {errors.cpaName && (
                <p className="text-red-500 text-start text-sm mt-1">
                  {errors.cpaName.message}
                </p>
              )}
            </div>
            <div className="flex flex-col w-full mb-4">
              <label className="text-black font-semibold text-start">
                CPA Email
              </label>
              <input
                {...register("cpaEmail")}
                className="p-2 text-[17px] rounded-md w-full"
              />
              {errors.cpaEmail && (
                <p className="text-red-500 text-start text-sm mt-1">
                  {errors.cpaEmail.message}
                </p>
              )}
            </div>
            <div className="flex flex-col w-full mb-4">
              <label className="text-black font-semibold text-start">
                CPA Phone Number
              </label>
              <input
                {...register("cpaPhone")}
                className="p-2 text-[17px] rounded-md w-full"
              />
              {errors.cpaPhone && (
                <p className="text-red-500 text-start text-sm mt-1">
                  {errors.cpaPhone.message}
                </p>
              )}
            </div>
            <button className="h-[32px] px-6 rounded-sm bg-[#356C58] text-white">
              {cpaLoading ? <Loader isLoading={true} size={20} /> : "SUBMIT"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default PurlDashboard;
